import jwtDefaultConfig from './jwtDefaultConfig';
import router from '@/router';
import axios from 'axios';
import { getUserData, getServidorData } from '@/auth/utils';
import { initialAbility } from '@/libs/acl/config';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
          config.headers['Cache-Control'] =
            'no-cache, no-store, must-revalidate, max-age=1800'; // Set the Cache-Control header
          config.headers.Expires = '0'; // Set the Expires header to '0'
        }

        const userData = getUserData();
        if (userData) {
          let institutoId = null;
          if (
            userData.role == 'master' ||
            (userData?.institutoId.length > 1 && userData.role != 'master')
          )
            institutoId = userData?.institutoSelecionado;
          else institutoId = userData?.institutoId[0];

          if (institutoId) config.headers.common['institutoLog'] = institutoId;
        }

        return config;
      },
      error => Promise.reject(error)
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        if (
          error.response &&
          error.response.status === 401 &&
          !error.config.url.includes('/notificacoes/buscarNotificacoes')
          // || this.isNetworkError(error)
        ) {
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
          localStorage.removeItem('userData');
          router.push('/login').catch(() => {});
        }
        return Promise.reject(error);
      }
    );
  }

  isNetworkError(err) {
    return !!err.isAxiosError && !err.response;
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  clearUserLocalStorage() {
    const storageTokenKeyName = localStorage.getItem(
      this.jwtConfig.storageTokenKeyName
    );
    const storageRefreshTokenKeyName = localStorage.getItem(
      this.jwtConfig.storageRefreshTokenKeyName
    );
    const userData = localStorage.getItem('userData');

    if (storageTokenKeyName != null && storageTokenKeyName != undefined)
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
    if (
      storageRefreshTokenKeyName != null &&
      storageRefreshTokenKeyName != undefined
    )
      localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
    if (userData != null && userData != undefined)
      localStorage.removeItem('userData');
  }

  clearServidorLocalStorage() {
    const servidorSelected = localStorage.getItem('servidorSelected');
    const formatoRecad = localStorage.getItem('formatoRecad');
    const polosDisponiveis = localStorage.getItem('polosDisponiveis');
    const paramForServidor = localStorage.getItem('paramForServidor');
    const documentoInstrucao = localStorage.getItem('documentoInstrucao');
    const questionarioSocioeconomico = localStorage.getItem(
      'questionarioSocioeconomico'
    );
    const questionarioRespondido = localStorage.getItem(
      'questionarioRespondido'
    );

    if (servidorSelected != null && servidorSelected != undefined)
      localStorage.removeItem('servidorSelected');
    if (servidorSelected != null && servidorSelected != undefined)
      localStorage.removeItem('servidorSelected');
    if (formatoRecad != null && formatoRecad != undefined)
      localStorage.removeItem('formatoRecad');
    if (polosDisponiveis != null && polosDisponiveis != undefined)
      localStorage.removeItem('polosDisponiveis');

    if (paramForServidor != null && paramForServidor != undefined)
      localStorage.removeItem('paramForServidor');
    if (documentoInstrucao != null && documentoInstrucao != undefined)
      localStorage.removeItem('documentoInstrucao');
    if (
      questionarioSocioeconomico != null &&
      questionarioSocioeconomico != undefined
    )
      localStorage.removeItem('questionarioSocioeconomico');
    if (questionarioRespondido != null && questionarioRespondido != undefined)
      localStorage.removeItem('questionarioRespondido');
  }

  clearFullocalStorage() {
    this.clearServidorLocalStorage();
    this.clearUserLocalStorage();

    const institutoSelecionadoServidor = localStorage.getItem(
      'institutoSelecionadoServidor'
    );
    const nomeSistema = localStorage.getItem('nomeSistema');

    if (
      institutoSelecionadoServidor != null &&
      institutoSelecionadoServidor != undefined
    )
      localStorage.removeItem('institutoSelecionadoServidor');
    if (nomeSistema != null || nomeSistema != undefined)
      localStorage.removeItem('nomeSistema');
  }

  recoverPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.recoverPasswordEndpoint, ...args);
  }

  changePasswordServidor(...args) {
    return this.axiosIns.post(
      this.jwtConfig.changePasswordServidorEndpoint,
      ...args
    );
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args);
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    });
  }

  verifyPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.verifyEndpoint, ...args);
  }

  authServidor(...args) {
    return this.axiosIns.post(this.jwtConfig.authServidorEndpoint, ...args);
  }

  forgotPassword(...args) {
    return this.axiosIns.post(this.jwtConfig.forgotEndpoint, ...args);
  }

  createPasswordServidor(...args) {
    return this.axiosIns.post(this.jwtConfig.passwordServidorEndpoint, ...args);
  }

  recoverPasswordServidor(...args) {
    return this.axiosIns.post(
      this.jwtConfig.recoverPassServidorEndpoint,
      ...args
    );
  }

  async pesquisar(controller, ...args) {
    return await this.axiosIns.post(controller, ...args);
  }

  async search(controller, ...args) {
    return await this.axiosIns.post(controller, ...args);
  }

  async post(controller, ...args) {
    return await this.axiosIns.post(controller, ...args);
  }

  async postDocumento(controller, onUploadProgress, ...args) {
    return await this.axiosIns.post(controller, ...args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  async postFormData(controller, args) {
    return await this.axiosIns.post(controller, args);
  }

  async downloadManual(controller) {
    return await this.axiosIns.get(controller, {
      responseType: 'arraybuffer',
    });
  }

  async downloadDocumento(controller, id) {
    return await this.axiosIns.get(controller + '/' + id);
  }

  async delete(controller, id) {
    return await this.axiosIns.delete(controller + '/' + id);
  }

  async del(controller, ...args) {
    return await this.axiosIns.delete(controller, ...args);
  }

  async edit(controller, id) {
    return await this.axiosIns.get(controller + '/' + id);
  }

  async update(controller, id, ...args) {
    return await this.axiosIns.patch(controller + '/' + id, ...args);
  }

  async patch(controller, ...args) {
    return await this.axiosIns.patch(controller, ...args);
  }

  async put(controller, ...args) {
    return await this.axiosIns.put(controller, ...args);
  }

  async updateDocumento(controller, onUploadProgress, id, ...args) {
    return await this.axiosIns.put(controller + '/' + id, ...args, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
  }

  async get(controller, ...args) {
    return await this.axiosIns.get(controller, ...args);
  }

  getAxiosIns() {
    return this.axiosIns;
  }
}
